<template>
  <v-form
    ref="form"
    v-model="valid"
    lazy-validation
    @submit.prevent="submitForm"
  >
    <div class="pa-5">
      <v-row align="center">
        <!-- <v-col>
          <v-file-input
            accept="image/*"
            label="File input"
          ></v-file-input>
        </v-col> -->
        <v-col cols="auto">
           <v-select label="Please Select" v-model="selectedContactSelectedContactNumber" :items="selectedContactContactNumbers"></v-select>
        </v-col>
      </v-row>
      <v-row class="mt-4 mb-1">
        <v-btn  color="accent" text  @click.stop="$emit('click-close')">
        Close
        </v-btn>

        <v-spacer></v-spacer>
        <v-btn
          v-if="selectedContactSelectedContactNumber"
          text
          :color="companyColor"
          :disabled="disabledSubmit"
          @click="sendSMS"
        >
          Send {{ formName }} to {{ sentReviewAs }}
        </v-btn>
      </v-row>
    </div>
  </v-form>
</template>

<script>
import { mergeExistingEntriesIntoForm } from "@/helpers/utilities"
import {changesSaved, somethingWentWrong } from "@/helpers/utilities"
import { mapActions, mapGetters } from "vuex"
import api from "@/store/api"


export default {
  props: {
    formName: {
      type: String,
      default: ""
    },
    companyColor: {
      type: String,
      default: "grey"
    },
    selectedCompany: {
      type: String
    },
    disabledSubmit: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    url: process.env.VUE_APP_BASE_URL,
    valid: true,
    sources: [],
    showEmail: false,
    form: {
      method: [],
      service: null
    },
    request: {
      customerName: "",
      customerEmail: "",
      source: "",
      agentID: 0
    },
    contactNumbers: [],
    selectedContactContactNumbers: [],
    selectedContactSelectedContactNumber: null,
  }),
  computed: {
    ...mapGetters("company", ["details"]),
    ...mapGetters("ui", ["formEditMode"]),
    ...mapGetters("addresses", ["addressItems"]),
    ...mapGetters("contacts", ["selectedContact", "contacts"]),
    ...mapGetters("employee", ["userId", "userName"]),
    serviceRules() {
      return [(value) => !!value || "Please Select a Service"]
    },
    contactsFiltered(){
      if(this.selectedContact){
         const filteredContact = this.selectedContact.filter(contact => contact.contactNumber = contact)
         return filteredContact.map((option) => ({
           text: option.contactNumber,
           value: option.contactId
         }))
      } else {
        const filteredContact = this.selectedContact.filter(contact => contact.altContactNumber = contact)
         return filteredContact.map((option) => ({
           text: option.altContactNumber,
           value: option.contactId
         }))
      }
    },
    altContactsFiltered(){
      const contacts = this.selectedContact
      return contacts.map((option) => ({
        text: option.altContactNumber,
        value: option.contactId
      }))
    },
    sentReviewAs(){
      return this.selectedContactSelectedContactNumber
    }

  },
  mounted(){
    this.fetchSources()
    if(this.selectedContact.contactNumber) {
      this.contactNumbers.push(this.selectedContact.contactNumber)
    }
    if(this.details.telephone) {
      this.contactNumbers.push(this.details.telephone)
    }

    this.contactNumbers.forEach((phone) => {
      const validPhone = phone && phone.startsWith("07")
      if(validPhone) {
        this.selectedContactContactNumbers.push(phone)
      }
    })
  },
  methods: {
    ...mapActions("addresses", ["selectAddress"]),
    ...mapActions("notes", ["fetchNotes"]),
    async fetchSources(){
      const response = await api.get('reviewSources')
      if(response.status === 200){
        this.sources = response.data.map((option) => ({
          text: option.value,
          value: option.id
        }))
      }
    },

    async sendSMS(){
      try {
      
        const url = this.url.includes('apidev') ? 'https://dev.utilityhive.com/external-requests' : 'https://hive.utilityhive.com/external-requests'
        const details = {
          submittedBy: this.userId,
          telephoneNumber: this.selectedContactSelectedContactNumber,
          url: url,
          companyId: this.details.companyId
        }
        const response = await api.post('submitSelfTerminationRequest', details)
        if(response.status === 200){
          this.selectedContactSelectedContactNumber = null
          changesSaved(`Request Sent to ${this.selectedContact.firstName} ${this.selectedContact.lastName}`)
          this.fetchNotes()
        }
      } catch (error) {
        somethingWentWrong(error);
      }
    },
  },
  created() {
    if (this.formEditMode) {
      Object.entries(this.details).forEach(
        mergeExistingEntriesIntoForm(this.form)
      )
    }
  }
}
</script>
